import React from "react";
import {
  CreateWorkShiftInput,
  WorkShiftStatus,
  useCreateWorkShiftMutation,
  useUpdateWorkShiftMutation,
} from "../graphql";
import { useForm } from "@mantine/form";
import { Button, Select, SimpleGrid, Text } from "@mantine/core";
import moment from "moment";
import { DateTimePicker } from "@mantine/dates";
import EmployeeInput from "./EmployeeInput";
import { showSuccessNotification } from "../utils/showNotification";

interface Props {
  onSubmit: (id: string) => void;
  workshift?: CreateWorkShiftInput & { id: string };
}

const STATUS: { value: WorkShiftStatus; label: string }[] = [
  { value: "SCHEDULED", label: "Scheduled" },
  { value: "CLOCKED_IN", label: "Clocked In (active)" },
  { value: "WORKED", label: "Worked (finished)" },
];

const WorkShiftForm: React.FC<Props> = ({ onSubmit, workshift }) => {
  const [createWorkShift, createWorkShiftResponse] = useCreateWorkShiftMutation(
    {
      refetchQueries: ["ListWorkShifts"],
      onCompleted: () => {
        showSuccessNotification("Work shift created successfully");
      }
    },
  );
  const [updateWorkShift, updateWorkShiftResponse] = useUpdateWorkShiftMutation(
    {
      refetchQueries: ["ListWorkShifts"],
      onCompleted: () => {
        showSuccessNotification("Work shift updated successfully");
      }
    },
  );
  const form = useForm<CreateWorkShiftInput>({
    initialValues: {
      status: workshift?.status || "CLOCKED_IN",
      start: workshift?.start || new Date().toISOString(),
      end: workshift?.end || new Date().toISOString(),
      employeeId: workshift?.employeeId || "",
    },
  });

  const handleSubmit = async (values: CreateWorkShiftInput) => {
    if (workshift) {
      await updateWorkShift({
        variables: {
          input: {
            ...values,
            id: workshift.id,
          },
        },
      });
      onSubmit(workshift.id);
    } else {
      const result = await createWorkShift({
        variables: {
          input: { ...values },
        },
      });
      if (result.data?.createWorkShift) {
        onSubmit(result.data.createWorkShift.id);
      }
    }
  };

  const errorMessage =
    createWorkShiftResponse.error?.message ||
    updateWorkShiftResponse.error?.message;

  return (
    <div>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <SimpleGrid>
          <DateTimePicker
            label="Clock In"
            valueFormat="MMM DD, YYYY hh:mm A"
            {...form.getInputProps("start")}
            required
            value={
              form.getInputProps("start").value
                ? moment(form.getInputProps("start").value).toDate() ||
                  new Date()
                : undefined
            }
            clearable
          />
          <DateTimePicker
            label="Clock Out"
            valueFormat="MMM DD, YYYY hh:mm A"
            {...form.getInputProps("end")}
            value={
              form.getInputProps("end").value
                ? moment(form.getInputProps("end").value).toDate() || new Date()
                : undefined
            }
            clearable
          />
          <Select
            label="Status"
            required
            data={STATUS}
            {...form.getInputProps("status")}
          />
          <EmployeeInput
            label="Employee"
            required
            {...form.getInputProps("employeeId")}
          />
        </SimpleGrid>
        {errorMessage && (
          <Text mt="md" c="red">
            {errorMessage}
          </Text>
        )}
        <Button fullWidth mt="md" type="submit">
          Save
        </Button>
      </form>
    </div>
  );
};

export default WorkShiftForm;
