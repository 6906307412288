import { createBrowserRouter } from "react-router-dom";

import PublicLayout from "../layouts/PublicLayout";
import AppLayout from "../layouts/AppLayout";

import ContactPage from "../pages/ContactPage";
import DashboardPage from "../pages/DashboardPage";
import FeaturesPage from "../pages/FeaturesPage";
import HomePage from "../pages/HomePage";
import NotFoundPage from "../pages/NotFoundPage";
import SettingsPage from "../pages/SettingsPage";
import TeamPage from "../pages/TeamPage";
import ErrorPage from "../pages/ErrorPage";
import AuthCallbackPage from "../pages/AuthCallbackPage";
import CustomersPage from "../pages/CustomersPage";
import JobsPage from "../pages/JobsPage";
import EmployeesPage from "../pages/EmployeesPage";
import CustomersCreatePage from "../pages/CustomersCreatePage";
import CustomersDetailsPage from "../pages/CustomersDetailsPage";
import CustomersUpdatePage from "../pages/CustomersUpdatePage";
import EmployeesCreatePage from "../pages/EmployeesCreatePage";
import EmployeesDetailsPage from "../pages/EmployeesDetailsPage";
import EmployeesUpdatePage from "../pages/EmployeesUpdatePage";
import JobsCreatePage from "../pages/JobsCreatePage";
import JobsDetailsPage from "../pages/JobsDetailsPage";
import JobsUpdatePage from "../pages/JobsUpdatePage";
import SignUpPage from "../pages/SignUpPage";
import AccountsPage from "../pages/AccountsPage";
import AccountsDetailsPage from "../pages/AccountsDetailsPage";
import AccountsCreatePage from "../pages/AccountsCreatePage";
import AccountsUpdatePage from "../pages/AccountsUpdatePage";
import SignUpConfirmPage from "../pages/SignUpConfirmPage";
import AuthLayout from "../layouts/AuthLayout";
import SignUpSuccessPage from "../pages/SignUpSuccessPage";
import CourtsPage from "../pages/CourtsPage";
import PricingPage from "../pages/PricingPage";
import WorkShiftsPage from "../pages/WorkShiftsPage";
import WorkShiftsCreatePage from "../pages/WorkShiftsCreatePage";
import WorkShiftsDetailsPage from "../pages/WorkShiftsDetailsPage";
import WorkShiftsUpdatePage from "../pages/WorkShiftsUpdatePage";
import ReportsPage from "../pages/ReportsPage";
import ReportsLayout from "../layouts/ReportsLayout";
import PayrollReportPage from "../pages/reports/PayrollReportPage";

export const appRouter = createBrowserRouter([
  {
    path: "/auth/callback",
    element: <AuthCallbackPage />,
  },
  {
    path: "/",
    element: <PublicLayout />,
    children: [
      { path: "", element: <HomePage /> },
      { path: "/team", element: <TeamPage /> },
      { path: "/features", element: <FeaturesPage /> },
      { path: "/pricing", element: <PricingPage /> },
      { path: "/contact", element: <ContactPage /> },
      { path: "/error", element: <ErrorPage /> },
      { path: "*", element: <NotFoundPage /> },
    ],
  },
  {
    path: "/signup",
    element: <AuthLayout />,
    children: [
      { path: "/signup", element: <SignUpPage /> },
      { path: "/signup/success", element: <SignUpSuccessPage /> },
      { path: "/signup/confirm", element: <SignUpConfirmPage /> },
    ],
  },
  {
    path: "/app",
    element: <AppLayout />,
    children: [
      { path: "/app", element: <DashboardPage /> },
      { path: "/app/customers", element: <CustomersPage /> },
      { path: "/app/customers/create", element: <CustomersCreatePage /> },
      { path: "/app/customers/:id", element: <CustomersDetailsPage /> },
      { path: "/app/customers/:id/update", element: <CustomersUpdatePage /> },
      { path: "/app/jobs", element: <JobsPage /> },
      { path: "/app/jobs/create", element: <JobsCreatePage /> },
      { path: "/app/jobs/:id", element: <JobsDetailsPage /> },
      { path: "/app/jobs/:id/update", element: <JobsUpdatePage /> },
      { path: "/app/employees", element: <EmployeesPage /> },
      { path: "/app/employees/create", element: <EmployeesCreatePage /> },
      { path: "/app/employees/:id", element: <EmployeesDetailsPage /> },
      { path: "/app/employees/:id/update", element: <EmployeesUpdatePage /> },
      { path: "/app/workshifts", element: <WorkShiftsPage /> },
      { path: "/app/workshifts/create", element: <WorkShiftsCreatePage /> },
      { path: "/app/workshifts/:id", element: <WorkShiftsDetailsPage /> },
      { path: "/app/workshifts/:id/update", element: <WorkShiftsUpdatePage /> },
      { path: "/app/courts", element: <CourtsPage /> },
      { path: "/app/settings", element: <SettingsPage /> },
      { path: "/app/accounts", element: <AccountsPage /> },
      { path: "/app/accounts/create", element: <AccountsCreatePage /> },
      { path: "/app/accounts/:id", element: <AccountsDetailsPage /> },
      { path: "/app/accounts/:id/update", element: <AccountsUpdatePage /> },
      { path: "/app/reports", element: <ReportsPage /> },
      { path: "/app/*", element: <NotFoundPage /> },
    ],
  },
  {
    path: "/app-reports",
    element: <ReportsLayout />,
    children: [
      { path: "/app-reports/payroll", element: <PayrollReportPage /> },
    ],
  },
]);
