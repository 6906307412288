import React from "react";
import { Paper, Title } from "@mantine/core";
import JobForm from "../components/JobForm";
import { useNavigate, useParams } from "react-router-dom";
import { useGetJobQuery } from "../graphql";

const JobsUpdatePage: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const getJob = useGetJobQuery({
    variables: { id: `${params.id}` },
  });

  const job = getJob.data?.getJob;

  return (
    <>
      <Title mb="sm">Update Job</Title>
      <Paper>
        {job && (
          <JobForm job={job} onSubmit={(id) => navigate(`/app/jobs/${id}`)} />
        )}
      </Paper>
    </>
  );
};

export default JobsUpdatePage;
