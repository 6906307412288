import { Box, Button, Center, Group, Title } from "@mantine/core";
import React from "react";
import type { XYCoord } from "react-dnd";
import { useDrop } from "react-dnd";
import {
  CourtFragment,
  useListCourtsQuery,
  useUpdateCourtMutation,
} from "../graphql";
import DraggableCourt from "./DraggableCourt";
import useCourtModal from "../hooks/useCourtModal";

const FacilityMap: React.FC = () => {
  const listCourtsResult = useListCourtsQuery();
  const [updateCourt] = useUpdateCourtMutation();
  const courts = listCourtsResult.data?.listCourts.data || [];
  const { openCourtModal } = useCourtModal({
    onComplete: () => {},
    onCancel: () => {},
  });

  const moveBox = async (court: CourtFragment, x: number, y: number) => {
    const newCourt = {
      id: court.id,
      name: court.name,
      surface: court.surface,
      coverage: court.coverage,
      color: court.color,
      location: {
        x: x,
        y: y,
        rotation: court.location.rotation,
      },
    };

    await updateCourt({
      variables: {
        input: newCourt,
      },
      optimisticResponse: {
        updateCourt: { ...court, location: newCourt.location },
      },
    });
  };

  const [, drop] = useDrop(
    () => ({
      accept: "court",
      drop: async (court: CourtFragment, monitor) => {
        const delta = monitor.getDifferenceFromInitialOffset() as XYCoord;
        const x = Math.round(court.location.x + delta.x);
        const y = Math.round(court.location.y + delta.y);
        await moveBox(court, x, y);
        return undefined;
      },
    }),
    [moveBox],
  );

  return (
    <>
      <Group justify="space-between">
        <Title order={1}>Facility Map</Title>
        <Button onClick={() => openCourtModal()}>New Court</Button>
      </Group>
      <Center>
        <Box
          ref={drop}
          mih="1000px"
          miw="800px"
          w="100%"
          pos="relative"
          style={{
            borderColor: "black",
            borderStyle: "solid",
            borderWidth: "1px",
          }}
        >
          {courts.map((court) => (
            <DraggableCourt
              key={court.id}
              court={court}
              draggable
              onEditClick={() => openCourtModal(court)}
            />
          ))}
        </Box>
      </Center>
    </>
  );
};

export default FacilityMap;
