import React from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import {
  CreateJobInput,
  JobStatus,
  useCreateJobMutation,
  useUpdateJobMutation,
} from "../graphql";
import { useForm } from "@mantine/form";
import { Button, Select, SimpleGrid, Text, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import EmployeeInput from "./EmployeeInput";
import CustomerInput from "./CustomerInput";
import { showSuccessNotification } from "../utils/showNotification";

interface Props {
  onSubmit: (jobId: string, customerId?: string) => void;
  job?: CreateJobInput & { id: string };
  customerId?: string;
  cols?: number;
  onSelectedCustomerId?: (id: string | undefined) => void;
}

const JOB_STATUS: { value: JobStatus; label: string }[] = [
  { value: "TODO", label: "To Do" },
  { value: "FINISHED", label: "Done" },
];

const JobForm: React.FC<Props> = ({
  onSubmit,
  job,
  customerId,
  cols,
  onSelectedCustomerId,
}) => {
  const [search] = useSearchParams();
  const [createJob, createJobResponse] = useCreateJobMutation({
    refetchQueries: ["ListJobs"],
    onCompleted: () => {
      showSuccessNotification("Job created successfully");
    },
  });
  const [updateJob, updateJobResponse] = useUpdateJobMutation({
    refetchQueries: ["ListJobs"],
    onCompleted: () => {
      showSuccessNotification("Job updated successfully");
    },
  });
  const form = useForm<CreateJobInput>({
    initialValues: {
      customerId: job?.customerId || search.get("customerId") || customerId,
      name: job?.name || search.get("name") || undefined,
      racket: job?.racket || search.get("racket") || undefined,
      stringName: job?.stringName || search.get("stringName") || undefined,
      tension: job?.tension || search.get("tension") || undefined,
      gauge: job?.gauge || search.get("gauge") || undefined,
      status: job?.status || "TODO",
      finishedAt: job?.finishedAt || new Date().toISOString(),
      finishedById: job?.finishedById,
      receivedAt: job?.receivedAt,
    },
    onValuesChange: (newValues, oldValues) => {
      if (newValues.customerId !== oldValues.customerId) {
        onSelectedCustomerId?.(newValues.customerId || undefined);
      }
    },
  });

  const handleSubmit = async (values: CreateJobInput) => {
    if (job) {
      await updateJob({
        variables: { input: { ...values, id: job.id } },
      });
      onSubmit(job.id, values.customerId || undefined);
    } else {
      const result = await createJob({ variables: { input: values } });
      if (result.data?.createJob) {
        onSubmit(result.data.createJob.id, values.customerId || undefined);
      }
    }
  };

  const errorMessage =
    createJobResponse.error?.message || updateJobResponse.error?.message;

  return (
    <div>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <SimpleGrid cols={{ sm: 1, md: cols || 2 }}>
          <CustomerInput
            label="Customer ID"
            {...form.getInputProps("customerId")}
          />
          <TextInput label="Name" {...form.getInputProps("name")} />
          <TextInput label="Racket" {...form.getInputProps("racket")} />
          <TextInput label="String" {...form.getInputProps("stringName")} />
          <TextInput label="Gauge" {...form.getInputProps("gauge")} />
          <TextInput label="Tension" {...form.getInputProps("tension")} />
          <Select
            label="Status"
            {...form.getInputProps("status")}
            data={JOB_STATUS}
          />
          <DateInput
            label="Recieved At"
            {...form.getInputProps("receivedAt")}
            value={
              form.getInputProps("receivedAt").value
                ? moment(form.getInputProps("receivedAt").value).toDate() ||
                  new Date()
                : undefined
            }
            clearable
          />
          <DateInput
            label="Finished At"
            {...form.getInputProps("finishedAt")}
            value={
              form.getInputProps("finishedAt").value
                ? moment(form.getInputProps("finishedAt").value).toDate() ||
                  new Date()
                : undefined
            }
            clearable
          />
          <EmployeeInput
            label="Finished By ID"
            {...form.getInputProps("finishedById")}
          />
        </SimpleGrid>
        {errorMessage && (
          <Text mt="md" c="red">
            {errorMessage}
          </Text>
        )}
        <Button fullWidth mt="md" type="submit">
          Save
        </Button>
      </form>
    </div>
  );
};

export default JobForm;
