import { Box, Center, Group, Paper, Text } from "@mantine/core";
import {
  IconBrandMastercard,
  IconBrandVisa,
  IconCreditCard,
} from "@tabler/icons-react";
import React from "react";
// import usePaymentMethodModal from "../hooks/usePaymentMethodModal";

interface Props {
  id: string;
  brand: string;
  lastFour: string;
  expMonth: number;
  expYear: number;
  isDefault: boolean;
}

const PaymentMethodDisplay: React.FC<Props> = ({
  // id,
  brand,
  lastFour,
  expMonth,
  expYear,
  isDefault,
}) => {
  // const { openPaymentMethodModal } = usePaymentMethodModal();
  const iconSize = 30;
  let icon = <IconCreditCard size={iconSize} />;

  if (brand === "visa") {
    icon = <IconBrandVisa size={iconSize} />;
  } else if (brand === "mastercard") {
    icon = <IconBrandMastercard size={iconSize} />;
  }

  const prettyBrand = brand.charAt(0).toUpperCase() + brand.slice(1);
  return (
    <Paper p="xs">
      <Group justify="space-between">
        <Group>
          <Box mr="md">
            <Center>{icon}</Center>
          </Box>
          <Text>{prettyBrand}</Text>
          <Text>•••• {lastFour}</Text>
          <Text>
            {expMonth}/{expYear}
          </Text>
          {isDefault && (
            <Text>
              <strong>Default</strong>
            </Text>
          )}
        </Group>
        {/* <Button
          size="xs"
          variant="outline"
          color="blue"
          onClick={() => openPaymentMethodModal(id)}
        >
          Update
        </Button> */}
      </Group>
    </Paper>
  );
};

export default PaymentMethodDisplay;
