import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Paper, Title } from "@mantine/core";
import FacilityMap from "../components/FacilityMap";

const CourtsPage: React.FC = () => {
  return (
    <>
      <Title mb="sm">Courts</Title>
      <Paper w="100%">
        <DndProvider backend={HTML5Backend}>
          <FacilityMap />
        </DndProvider>
      </Paper>
    </>
  );
};

export default CourtsPage;
