import { Button } from "@mantine/core";
import React from "react";
import useUpdateJobStatusModal from "../hooks/useUpdateJobStatusModal";
import { IconCheck } from "@tabler/icons-react";
import { showSuccessNotification } from "../utils/showNotification";
import { usePermissions } from "../contexts/PermissionsContext";

interface Props {
  jobIds?: string[];
  onComplete?: () => void;
  hidden?: boolean;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
}

const FinishJobsButton: React.FC<Props> = ({
  jobIds,
  onComplete,
  hidden,
  size,
}) => {
  const permissions = usePermissions();
  const [loading, setLoading] = React.useState(false);
  const { openFinishJobsModal } = useUpdateJobStatusModal({
    onComplete: () => {
      onComplete?.();
      setLoading(false);
      showSuccessNotification("Jobs finished successfully");
    },
    onCancel: () => {
      setLoading(false);
    },
  });

  return (
    <Button
      style={{ display: hidden ? "none" : "" }}
      onClick={() => {
        setLoading(true);
        openFinishJobsModal(jobIds || []);
      }}
      disabled={!jobIds || jobIds.length === 0}
      loading={loading}
      rightSection={<IconCheck />}
      size={size}
      display={!permissions.can("updateJob") ? "hidden" : undefined}
    >
      Finish Job{jobIds && jobIds.length > 1 ? "s" : ""}
    </Button>
  );
};

export default FinishJobsButton;
