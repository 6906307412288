import React from "react";
import { useSearchParams } from "react-router-dom";
import { Table, Title, rem } from "@mantine/core";
import { usePayrollReportQuery } from "../../graphql";
import DateDisplay from "../../components/DateDisplay";
import moment from "moment";

const PayrollReportPage: React.FC = () => {
  const [search] = useSearchParams();
  const start = search.get("start");
  const end = search.get("end");

  const prettyStart = moment(start).format("MMM Do YY");
  const prettyEnd = moment(end).format("MMM Do YY");

  React.useEffect(() => {
    document.title = `Payroll Report - ${prettyStart} - ${prettyEnd}`;
  });

  const payrollReportResponse = usePayrollReportQuery({
    variables: { start, end },
    onCompleted: () => {
      window.print();
    },
  });
  return (
    <>
      <Title order={3} mb="sm">
        Payroll Report - {prettyStart} - {prettyEnd}
      </Title>
      {payrollReportResponse.data?.listEmployees.data
        .filter(
          (e) => e.jobsFinished.data.length > 0 || e.workShifts.data.length > 0
        )
        .map((employee) => (
          <div key={employee.id}>
            <Title order={5}>
              {employee.firstName} {employee.lastName}
            </Title>
            <Title order={6}>Work Shifts</Title>
            <Table
              style={{ fontSize: "10px" }}
              verticalSpacing={rem(0.5)}
              data={{
                head: ["Date", "Clock In", "Clock Out", "Hours Worked"],
                body: employee.workShifts.data.map((shift) => [
                  <DateDisplay format="MM/DD/YY" value={shift.start} />,
                  <DateDisplay format="hh:mm A" value={shift.start} />,
                  <DateDisplay format="hh:mm A" value={shift.end} />,
                  `${
                    Math.round(
                      moment(shift.end).diff(
                        moment(shift.start),
                        "hours",
                        true
                      ) * 100
                    ) / 100
                  } hours`,
                ]),
                foot: [
                  null,
                  null,
                  null,
                  `Total: ${
                    Math.round(
                      employee.workShifts.data.reduce(
                        (acc, shift) =>
                          acc +
                          moment(shift.end).diff(
                            moment(shift.start),
                            "hours",
                            true
                          ),
                        0
                      ) * 100
                    ) / 100
                  } hours`,
                ],
              }}
            />{" "}
            <Title order={6}>Rackets Strung</Title>
            <Table
              style={{ fontSize: "10px" }}
              verticalSpacing={rem(0.5)}
              data={{
                head: [
                  "Job Name",
                  "Racket / String",
                  "Job Created At",
                  "Finished At",
                ],
                body: employee.jobsFinished.data.map((job) => [
                  job.name,
                  `${job.racket} / ${job.stringName}`,
                  <DateDisplay
                    format="MM/DD/YY hh:mm A"
                    value={job.createdAt}
                  />,
                  <DateDisplay
                    format="MM/DD/YY hh:mm A"
                    value={job.finishedAt}
                  />,
                ]),
                foot: [
                  null,
                  null,
                  null,
                  `Total: ${employee.jobsFinished.data.length} rackets`,
                ],
              }}
            />
            <hr />
          </div>
        ))}
    </>
  );
};

export default PayrollReportPage;
