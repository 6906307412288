import React from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  Title,
  Grid,
  Text,
  Paper,
  Table,
  rem,
  Pagination,
  Group,
  Center,
  Button,
  Anchor,
  Checkbox,
  LoadingOverlay,
} from "@mantine/core";
import { BarChart } from "@mantine/charts";
import {
  JobFilter,
  useGetAccountSummaryQuery,
  useListJobsQuery,
} from "../graphql";
import DateDisplay from "../components/DateDisplay";
import useListVariables from "../hooks/useListVariables";
import TableHeaderCell from "../components/TableHeaderCell";
import { IconClipboardList, IconUsersGroup } from "@tabler/icons-react";
import FinishJobsButton from "../components/FinishJobsButton";
import ClockInOutButton from "../components/ClockInOutButton";
import { usePermissions } from "../contexts/PermissionsContext";

const DashboardPage: React.FC = () => {
  const navigate = useNavigate();
  const permissions = usePermissions();
  const [selectedJobs, setSelectedJobs] = React.useState<string[]>([]);
  const listVariables = useListVariables<JobFilter>({
    defaultSort: { field: "updatedAt", order: "DESC" },
    defaultPagination: { page: 1, perPage: 30 },
    defaultFilter: { status: "TODO" },
  });

  const accountSummary = useGetAccountSummaryQuery({
    fetchPolicy: "cache-and-network",
  });
  const jobs = useListJobsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      pagination: listVariables.pagination,
      sort: listVariables.sort,
      filter: listVariables.filter,
    },
  });

  return (
    <>
      <Group justify="space-between" mb="sm">
        <Title mb={0}>Dashboard</Title>
        <Group gap="sm">
          <ClockInOutButton />
          <Button
            onClick={() => navigate("/app/jobs/create")}
            rightSection={<IconClipboardList />}
            display={!permissions.can("createJob") ? "hidden" : undefined}
          >
            Create Job
          </Button>
          <Button
            onClick={() => navigate("/app/customers/create")}
            rightSection={<IconUsersGroup />}
            display={!permissions.can("createCustomer") ? "hidden" : undefined}
          >
            CreateCustomer
          </Button>
        </Group>
      </Group>
      <Grid>
        <Grid.Col span={{ md: 3, sm: 12 }}>
          <Paper h="100%" pos="relative">
            <Center h="100%">
              <div>
                <Text size="96px" fw={900} ta="center">
                  {accountSummary.data?.accountSummary.unfinishedJobs !==
                  undefined
                    ? accountSummary.data?.accountSummary.unfinishedJobs
                    : ""}
                </Text>
                <Text ta="center" size="sm" fw={700} mb="md">
                  Pending Jobs
                </Text>
              </div>
            </Center>
            <LoadingOverlay
              visible={accountSummary.loading}
              zIndex={1000}
              overlayProps={{ radius: "sm", blur: 2 }}
            />
          </Paper>
        </Grid.Col>
        <Grid.Col span={{ md: 9, sm: 12 }}>
          <Paper pos="relative">
            <Text size="sm" fw={700} mb="sm">
              Jobs Finished by Month
            </Text>
            <BarChart
              data={(accountSummary.data?.accountSummary.jobsByMonth || []).map(
                (job) => ({
                  date: moment(job.date).format("MMM YYYY"),
                  Jobs: job.jobs,
                })
              )}
              dataKey="date"
              valueFormatter={(value) =>
                new Intl.NumberFormat("en-US").format(value)
              }
              series={[{ name: "Jobs", color: "blue.5" }]}
              h={250}
              tickLine="y"
            />
            <LoadingOverlay
              visible={accountSummary.loading}
              zIndex={1000}
              overlayProps={{ radius: "sm", blur: 2 }}
            />
          </Paper>
        </Grid.Col>
        <Grid.Col span={12}>
          <Paper mih={rem(300)}>
            <Group mb="md" justify="space-between">
              <Text size="sm" fw={700} mb="sm">
                Unfinished Jobs
              </Text>

              <FinishJobsButton
                jobIds={selectedJobs}
                onComplete={() => {
                  jobs.refetch();
                  accountSummary.refetch();
                  setSelectedJobs([]);
                }}
              />
            </Group>
            <Table.ScrollContainer minWidth={500}>
              <Table>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th w={0}></Table.Th>
                    <TableHeaderCell
                      label="Job Name"
                      field="name"
                      vars={listVariables}
                    />
                    <Table.Th>Customer</Table.Th>
                    <TableHeaderCell
                      label="String"
                      field="stringName"
                      vars={listVariables}
                    />
                    <TableHeaderCell
                      label="Racket"
                      field="racket"
                      vars={listVariables}
                    />
                    <TableHeaderCell
                      label="Tension"
                      field="tension"
                      vars={listVariables}
                    />
                    <TableHeaderCell
                      label="Gauge"
                      field="gauge"
                      vars={listVariables}
                    />
                    <TableHeaderCell
                      label="Received On"
                      field="receivedAt"
                      vars={listVariables}
                    />
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  {jobs.data?.listJobs.data.map((job) => (
                    <Table.Tr
                      key={job.id}
                      bg={
                        selectedJobs.includes(job.id)
                          ? "var(--mantine-color-blue-light)"
                          : undefined
                      }
                    >
                      <Table.Td>
                        <Checkbox
                          aria-label="Select Job"
                          checked={selectedJobs.includes(job.id)}
                          onChange={(event) =>
                            setSelectedJobs(
                              event.currentTarget.checked
                                ? [...selectedJobs, job.id]
                                : selectedJobs.filter(
                                    (jobId) => jobId !== job.id
                                  )
                            )
                          }
                        />
                      </Table.Td>
                      <Table.Td>
                        <Anchor onClick={() => navigate(`/app/jobs/${job.id}`)}>
                          {job.name}
                        </Anchor>
                      </Table.Td>
                      <Table.Td>
                        <Anchor
                          onClick={() =>
                            navigate(`/app/customers/${job.customerId}`)
                          }
                        >
                          {job.customer?.firstName} {job.customer?.lastName}
                        </Anchor>
                      </Table.Td>
                      <Table.Td>{job.stringName}</Table.Td>
                      <Table.Td>{job.racket}</Table.Td>
                      <Table.Td>{job.tension}</Table.Td>
                      <Table.Td>{job.gauge}</Table.Td>
                      <Table.Td>
                        <DateDisplay value={job.receivedAt || job.createdAt} />
                      </Table.Td>
                    </Table.Tr>
                  ))}
                </Table.Tbody>
              </Table>
            </Table.ScrollContainer>
            <Group justify="space-evenly">
              <Pagination
                hidden={
                  !jobs.data?.listJobs.total ||
                  jobs.data?.listJobs.total < listVariables.pagination.perPage
                }
                mt={rem(20)}
                value={listVariables.pagination.page}
                onChange={listVariables.setPage}
                total={
                  (jobs.data?.listJobs.total || 0) /
                  listVariables.pagination.perPage
                }
              />
            </Group>
          </Paper>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default DashboardPage;
