import React from "react";
import { Paper, SimpleGrid, Table, Title } from "@mantine/core";
import JobForm from "../components/JobForm";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useListJobsLazyQuery } from "../graphql";

const JobsCreatePage: React.FC = () => {
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const [selectedCustomerId, setSelectedCustomerId] = React.useState<
    string | undefined
  >(undefined);

  const initialCustomerId = search.get("customerId");

  const [listJobs, listJobsResponse] = useListJobsLazyQuery();

  const tableBody = listJobsResponse.data?.listJobs.data.map((job) => [
    job.name,
    job.racket,
    job.stringName,
    job.tension,
    job.gauge,
  ]);

  React.useEffect(() => {
    if (initialCustomerId !== null) {
      setSelectedCustomerId(initialCustomerId);
    }
  }, [initialCustomerId]);

  React.useEffect(() => {
    if (selectedCustomerId) {
      listJobs({
        variables: {
          filter: { customerId: selectedCustomerId },
          pagination: { page: 1, perPage: 10 },
        },
      });
    }
  }, [listJobs, selectedCustomerId]);

  return (
    <>
      <Title mb="sm">Create Job</Title>
      <SimpleGrid>
        <Paper>
          <JobForm
            onSubmit={(jobId, customerId) =>
              customerId
                ? navigate(`/app/customers/${customerId}`)
                : navigate(`/app/jobs/${jobId}`)
            }
            onSelectedCustomerId={setSelectedCustomerId}
          />
        </Paper>
        <Paper>
          <Title order={4}>Past Jobs</Title>
          <Table.ScrollContainer minWidth={500}>
            <Table
              data={{
                head: ["Job Name", "Racket", "String", "Tension", "Gauge"],
                body: selectedCustomerId ? tableBody : undefined,
              }}
            />
          </Table.ScrollContainer>
        </Paper>
      </SimpleGrid>
    </>
  );
};

export default JobsCreatePage;
