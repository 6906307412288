import React from "react";
import {
  CourtCoverage,
  CourtSurface,
  CreateCourtInput,
  useCreateCourtMutation,
  useUpdateCourtMutation,
} from "../graphql";
import { useForm } from "@mantine/form";
import {
  Button,
  ColorInput,
  NumberInput,
  Select,
  SimpleGrid,
  Text,
  TextInput,
} from "@mantine/core";
import { showSuccessNotification } from "../utils/showNotification";

interface Props {
  onSubmit: (id: string) => void;
  cols?: number;
  court?: CreateCourtInput & { id: string };
}

type FormData = Omit<CreateCourtInput, "location"> & {
  rotation: number;
  x: number;
  y: number;
};

const COVERAGE: { value: CourtCoverage; label: string }[] = [
  { value: "OUTDOOR_UNCOVERED", label: "Outdoor" },
  { value: "OUTDOOR_COVERED", label: "Outdoor (covered)" },
  { value: "INDOOR", label: "Indoor" },
];

const SURFACE: { value: CourtSurface; label: string }[] = [
  { value: "HARD", label: "Hard" },
  { value: "CLAY", label: "Clay" },
  { value: "GRASS", label: "Grass" },
  // { value: "CARPET", label: "Carpet" },
];

const CourtForm: React.FC<Props> = ({ onSubmit, court, cols }) => {
  const [createCourt, createCourtResponse] = useCreateCourtMutation({
    refetchQueries: ["ListCourts"],
    onCompleted: () => {
      showSuccessNotification("Court created successfully");
    },
  });
  const [updateCourt, updateCourtResponse] = useUpdateCourtMutation({
    refetchQueries: ["ListCourts"],
    onCompleted: () => {
      showSuccessNotification("Court updated successfully");
    },
  });
  const form = useForm<FormData>({
    initialValues: {
      name: court?.name || "",
      surface: court?.surface || "HARD",
      coverage: court?.coverage || "OUTDOOR_UNCOVERED",
      color: court?.color || "",
      rotation: court?.location.rotation || 0,
      x: court?.location.x || 0,
      y: court?.location.y || 0,
    },
  });

  const handleSubmit = async (data: FormData) => {
    const { rotation, x, y, ...values } = data;

    const location = { rotation, x, y };

    if (court) {
      await updateCourt({
        variables: {
          input: {
            ...values,
            id: court.id,
            location: location,
          },
        },
      });
      onSubmit(court.id);
    } else {
      const result = await createCourt({
        variables: {
          input: {
            ...values,
            location: location,
          },
        },
      });
      if (result.data?.createCourt) {
        onSubmit(result.data.createCourt.id);
      }
    }
  };

  const errorMessage =
    createCourtResponse.error?.message || updateCourtResponse.error?.message;

  return (
    <div>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <SimpleGrid cols={{ sm: cols || 1, md: cols || 2 }}>
          <TextInput label="Name" {...form.getInputProps("name")} />
          <NumberInput
            label="X Coordinate"
            min={0}
            max={1000}
            {...form.getInputProps("x")}
          />
          <NumberInput
            label="Y Coordinate"
            min={0}
            max={1000}
            {...form.getInputProps("y")}
          />
          <NumberInput
            label="Rotation"
            min={0}
            max={360}
            suffix="°"
            {...form.getInputProps("rotation")}
          />
          <ColorInput
            label="Color"
            format="hex"
            withEyeDropper={false}
            disallowInput
            withPicker={false}
            swatches={[
              "#2e2e2e",
              "#868e96",
              "#fa5252",
              "#e64980",
              "#be4bdb",
              "#7950f2",
              "#4c6ef5",
              "#228be6",
              "#15aabf",
              "#12b886",
              "#40c057",
              "#82c91e",
              "#fab005",
              "#fd7e14",
            ]}
            {...form.getInputProps("color")}
          />
          <Select
            label="Court Coverage"
            {...form.getInputProps("coverage")}
            data={COVERAGE}
          />
          <Select
            label="Court Surface"
            {...form.getInputProps("surface")}
            data={SURFACE}
          />
        </SimpleGrid>
        {errorMessage && (
          <Text mt="md" c="red">
            {errorMessage}
          </Text>
        )}
        <Button fullWidth mt="md" type="submit">
          Save
        </Button>
      </form>
    </div>
  );
};

export default CourtForm;
