import React from "react";
import { Button, SimpleGrid, TextInput, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { AccountFragment, useSaveAccountSettingsMutation } from "../graphql";
import { showSuccessNotification } from "../utils/showNotification";

interface FormData {
  name: string;
  phone: string;
  jobFinishedSmsTemplate: string;
}

interface Props {
  account: AccountFragment;
  onComplete?: () => void;
}

const AccountSettingsForm: React.FC<Props> = ({ account, onComplete }) => {
  const form = useForm<FormData>({
    initialValues: {
      name: account.name,
      phone: account.phone,
      jobFinishedSmsTemplate: account.jobFinishedSmsTemplate || "",
    },
  });

  const [saveAccountSettings, saveAccountSettingsResponse] =
    useSaveAccountSettingsMutation({
      onCompleted: () => {
        showSuccessNotification("Account settings saved successfully");
        onComplete?.();
      },
    });

  const handleSubmit = (data: FormData) => {
    console.log(data);
    saveAccountSettings({
      variables: {
        input: {
          name: data.name,
          phone: data.phone,
          jobFinishedSmsTemplate: data.jobFinishedSmsTemplate,
          businessHours: [],
        },
      },
    });
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <SimpleGrid>
        <TextInput
          label="Company Name"
          required
          {...form.getInputProps("name")}
        />
        <TextInput
          label="Phone Number"
          required
          {...form.getInputProps("phone")}
        />
        <Textarea
          label="Customer Job Notification Message"
          required
          {...form.getInputProps("jobFinishedSmsTemplate")}
        />
      </SimpleGrid>
      <Button
        mt="lg"
        type="submit"
        loading={saveAccountSettingsResponse.loading}
      >
        Save
      </Button>
    </form>
  );
};

export default AccountSettingsForm;
