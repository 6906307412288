import React from "react";
import { Paper, Title } from "@mantine/core";
import CustomerForm from "../components/CustomerForm";
import { useNavigate } from "react-router-dom";

const CustomersCreatePage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <Title mb="sm">Create Customer</Title>
      <Paper>
        <CustomerForm onSubmit={(id) => navigate(`/app/customers/${id}`)} />
      </Paper>
    </>
  );
};

export default CustomersCreatePage;
