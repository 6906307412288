import { modals } from "@mantine/modals";
import { useUpdateJobsStatusMutation } from "../graphql";
import UpdateJobsStatusForm from "../components/UpdateJobsStatusForm";
import { showSuccessNotification } from "../utils/showNotification";

interface Args {
  onComplete: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
}

function useFinishJobsModal(args: Args) {
  const [updateJobsStatus, updateJobsStatusResponse] =
    useUpdateJobsStatusMutation({
      onCompleted: () => {
        showSuccessNotification("Jobs finished successfully");
      },
    });

  const handleSubmit =
    (jobIds: string[]) =>
    (data: { employeeId: string; notifyCustomer: boolean }) => {
      updateJobsStatus({
        variables: {
          input: {
            status: "FINISHED",
            ids: jobIds,
            finishedById: data.employeeId,
            sendNotification: data.notifyCustomer,
          },
        },
      });
    };

  const openFinishJobsModal = (jobIds: string[]) => {
    modals.open({
      title: "Mark Jobs as Finished",
      children: (
        <UpdateJobsStatusForm
          loading={updateJobsStatusResponse.loading}
          onSubmit={(data) => {
            handleSubmit(jobIds)(data);
            args.onComplete();
            modals.closeAll();
          }}
        />
      ),
      onClose: args.onCancel,
    });
  };

  return { openFinishJobsModal };
}

export default useFinishJobsModal;
