import React from "react";

interface Props {
  scale?: number;
  lineColor?: string;
}

const TennisCourtSVG: React.FC<Props> = (props) => {
  const scale = props.scale || 1;
  const lineColor = props.lineColor || "white";
  return (
    <svg
      width={120 * scale}
      height={60 * scale}
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Doubles Court */}
      <rect
        x={21 * scale}
        y={12 * scale}
        width={78 * scale}
        height={36 * scale}
        fill="none"
        stroke={lineColor}
        stroke-width={1 * scale}
      />
      {/* Singles Court */}
      <rect
        x={21 * scale}
        y={(12 + 4.5) * scale}
        width={78 * scale}
        height={(36 - 4.5 - 4.5) * scale}
        fill="none"
        stroke={lineColor}
        stroke-width={1 * scale}
      />
      {/* Top Service Box */}
      <rect
        x={(21 + 18) * scale}
        y={(12 + 4.5) * scale}
        width={(78 - 18 - 18) * scale}
        height={(36 - 4.5 - 4.5 - 13.5) * scale}
        fill="none"
        stroke={lineColor}
        stroke-width={1 * scale}
      />
      {/* Bottom Service Box */}
      <rect
        x={(21 + 18) * scale}
        y={(12 + 4.5 + 13.5) * scale}
        width={(78 - 18 - 18) * scale}
        height={(36 - 4.5 - 4.5 - 13.5) * scale}
        fill="none"
        stroke={lineColor}
        stroke-width={1 * scale}
      />
      {/* Net */}
      <line
        x1={60 * scale}
        y1={(12 - 3) * scale}
        x2={60 * scale}
        y2={(48 + 3) * scale}
        stroke={lineColor}
        stroke-width={1 * scale}
      />
      {/* Left Hash Mark */}
      <line
        x1={21 * scale}
        y1={(60 / 2) * scale}
        x2={(21 + 2) * scale}
        y2={(60 / 2) * scale}
        stroke={lineColor}
        stroke-width={1 * scale}
      />
      {/* Right Hash Mark */}
      <line
        x1={(21 + 78) * scale}
        y1={(60 / 2) * scale}
        x2={(21 + 78 - 2) * scale}
        y2={(60 / 2) * scale}
        stroke={lineColor}
        stroke-width={1 * scale}
      />
    </svg>
  );
};

export default TennisCourtSVG;
