import React from "react";
import { Paper, Title } from "@mantine/core";
import CustomerForm from "../components/CustomerForm";
import { useNavigate, useParams } from "react-router-dom";
import { useGetCustomerQuery } from "../graphql";

const CustomersUpdatePage: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const getCustomer = useGetCustomerQuery({
    variables: { id: `${params.id}` },
  });

  const customer = getCustomer.data?.getCustomer;

  return (
    <>
      <Title mb="sm">Update Customer</Title>
      <Paper>
        {customer && (
          <CustomerForm
            customer={customer}
            onSubmit={(id) => navigate(`/app/customers/${id}`)}
          />
        )}
      </Paper>
    </>
  );
};

export default CustomersUpdatePage;
