import React from "react";
import { Button } from "@mantine/core";
import { IconClockPlay, IconClockStop } from "@tabler/icons-react";

import useClockInOutModal from "../hooks/useClockInOutModal";
import { useGetActiveWorkShiftQuery } from "../graphql";
import moment from "moment";

interface Props {
  onComplete?: () => void;
  hidden?: boolean;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
}

const ClockInOutButton: React.FC<Props> = ({ onComplete, hidden, size }) => {
  const activeWorkShiftResp = useGetActiveWorkShiftQuery();
  const { openClockInOutModal } = useClockInOutModal({
    onComplete: () => {
      onComplete?.();
    },
    onCancel: () => {},
  });

  const activeWorkShift = activeWorkShiftResp.data?.getActiveWorkShift;
  const shiftDuration = activeWorkShift
    ? moment.duration(moment().diff(activeWorkShift.start))
    : moment.duration(0);

  const shiftDurationStr = `${shiftDuration.hours()}h ${shiftDuration.minutes()}m`;

  return (
    <Button
      style={{ display: hidden ? "none" : "" }}
      onClick={() => {
        openClockInOutModal();
      }}
      loading={activeWorkShiftResp.loading}
      rightSection={activeWorkShift ? <IconClockStop /> : <IconClockPlay />}
      size={size}
      color={
        activeWorkShiftResp.loading
          ? "gray"
          : activeWorkShift
          ? "red"
          : undefined
      }
    >
      Clock {activeWorkShift ? "Out" : "In"}{" "}
      {activeWorkShift ? `(Current Shift: ${shiftDurationStr})` : ""}
    </Button>
  );
};

export default ClockInOutButton;
