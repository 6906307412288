import React from "react";
import moment from "moment";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  NavLink,
  AppShell,
  Burger,
  Button,
  Group,
  rem,
  Text,
  Alert,
  Box,
  Image,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconClipboardList,
  // IconBallTennis,
  IconClock,
  IconGraph,
  IconHome2,
  IconInfoCircle,
  IconLock,
  IconLogout,
  IconSettings,
  IconUserCog,
  IconUsersGroup,
} from "@tabler/icons-react";

import { WebsocketProvider } from "../contexts/WebsocketContext";
import { useAuth } from "../contexts/AuthContext";
import { useGetMyAccountQuery } from "../graphql";
import Logo from "../assets/topLeftLogo.png";
import { usePermissions } from "../contexts/PermissionsContext";

const links = [
  {
    link: "/app",
    label: "Home",
    icon: <IconHome2 />,
    permission: undefined,
    roles: [],
  },
  {
    link: "/app/customers",
    label: "Customers",
    icon: <IconUsersGroup />,
    permission: "listCustomers",
    roles: [],
  },
  {
    link: "/app/jobs",
    label: "Jobs",
    icon: <IconClipboardList />,
    permission: "listJobs",
    roles: [],
  },
  {
    link: "/app/employees",
    label: "Employees",
    icon: <IconUserCog />,
    permission: "listEmployees",
    roles: [],
  },
  // { link: "/app/courts", label: "Courts", icon: <IconBallTennis /> ,permission: "listCourts", roles: [] },
  {
    link: "/app/workshifts",
    label: "Time Clock",
    icon: <IconClock />,
    permission: "listWorkShifts",
    roles: [],
  },
  {
    link: "/app/reports",
    label: "Reports",
    icon: <IconGraph />,
    permission: undefined,
    roles: ["ACCOUNT_OWNER", "ACCOUNT_ADMIN"],
  },
  {
    link: "/app/settings",
    label: "Settings",
    icon: <IconSettings />,
    permission: undefined,
    roles: ["ACCOUNT_OWNER", "ACCOUNT_ADMIN"],
  },
] as const;

const HEADER_HEIGHT = rem(60);
const NAVBAR_WIDTH = rem(200);

const AppLayout: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const [opened, { toggle }] = useDisclosure();
  const [showAlert, setShowAlert] = React.useState(true);
  const permissions = usePermissions();

  const myAccount = useGetMyAccountQuery();

  const account = myAccount.data?.myAccount;

  const daysLeftInTrial = account?.trialEndDate
    ? moment(account?.trialEndDate).diff(moment(), "days")
    : undefined;

  const showTrialBanner = account?.status === "TRIAL";

  const trialBannerColor =
    !daysLeftInTrial || daysLeftInTrial > 15
      ? "yellow"
      : daysLeftInTrial > 5
      ? "orange"
      : "red";

  const items = links
    .filter((link) => {
      if (link.permission) return permissions.can(link.permission);
      if (link.roles.length) return permissions.isRole([...link.roles]);
      return true;
    })
    .map((link) => (
      <NavLink
        key={link.link}
        href={link.link}
        leftSection={link.icon}
        mt="md"
        active={location.pathname === link.link}
        onClick={(event) => {
          event.preventDefault();
          if (opened) {
            toggle();
          }
          navigate(link.link);
        }}
        label={link.label}
      />
    ));

  if (!auth.isAuthenticated) {
    auth.login(`${location.pathname}${location.search}`);
    return <></>;
  }

  return (
    <WebsocketProvider>
      <AppShell
        header={{ height: HEADER_HEIGHT }}
        navbar={{
          width: NAVBAR_WIDTH,
          breakpoint: "sm",
          collapsed: { mobile: !opened },
        }}
      >
        <AppShell.Header
          bg="var(--mantine-color-blue-9)"
          style={{ color: "white" }}
        >
          <Group justify="space-between" h="100%" px="lg">
            <Group>
              <Burger
                opened={opened}
                onClick={toggle}
                hiddenFrom="sm"
                size="sm"
                color="white"
              />
              <Image
                onClick={() => {
                  close();
                  navigate("/");
                }}
                w={rem(180)}
                src={Logo}
                alt="Tennis Shop Guru"
              />
            </Group>
            {showTrialBanner &&
              daysLeftInTrial &&
              daysLeftInTrial > 0 &&
              showAlert && (
                <Alert
                  icon={<IconInfoCircle />}
                  color={trialBannerColor}
                  variant="filled"
                  h={HEADER_HEIGHT}
                  style={{ cursor: "pointer" }}
                  title={
                    <>
                      You have {daysLeftInTrial} days left in trial -{" "}
                      <a
                        style={{ color: "white" }}
                        href="/app/settings"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/app/settings");
                        }}
                      >
                        Click here
                      </a>{" "}
                      to select a plan
                    </>
                  }
                  withCloseButton
                  onClose={() => setShowAlert(false)}
                ></Alert>
              )}

            <Group visibleFrom="sm">
              <Text>
                {auth.user?.given_name} {auth.user?.family_name}
              </Text>
              <Button
                color="blue"
                size="xs"
                onClick={() => auth.logout()}
                rightSection={<IconLogout />}
              >
                Logout
              </Button>
            </Group>
          </Group>
        </AppShell.Header>
        <AppShell.Navbar bg="white">
          <AppShell.Section grow>{items}</AppShell.Section>
          <AppShell.Section>
            <Box p="md" hiddenFrom="sm">
              <Text ta="center" my="sm">
                {auth.user?.given_name} {auth.user?.family_name}
              </Text>
              <Button
                mt="sm"
                fullWidth
                color="blue"
                size="xs"
                onClick={() => auth.logout()}
                rightSection={<IconLogout />}
              >
                Logout
              </Button>
            </Box>
            {auth.isAdmin && (
              <NavLink
                href="/app/accounts"
                leftSection={<IconLock />}
                active={location.pathname === "/app/accounts"}
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/app/accounts");
                }}
                label="Accounts"
              />
            )}
          </AppShell.Section>
        </AppShell.Navbar>
        <AppShell.Main bg="var(--mantine-color-gray-1)">
          <Box
            p="sm"
            mih={`calc(100vh - ${HEADER_HEIGHT})`}
            mah={`calc(100vh - ${HEADER_HEIGHT})`}
            style={{ overflowY: "auto" }}
          >
            <Outlet />
          </Box>
        </AppShell.Main>
      </AppShell>
    </WebsocketProvider>
  );
};

export default AppLayout;
