import { useSearchParams } from "react-router-dom";
import { PaginationInput, SortInput } from "../graphql";

interface Args<Filter> {
  defaultSort?: SortInput;
  defaultPagination?: PaginationInput;
  defaultFilter?: Filter;
  prefix?: string;
}

export interface ListVariables<Filter> {
  sort: SortInput;
  setSort: (sort: SortInput) => void;
  pagination: { page: number; perPage: number };
  setPage: (page: number) => void;
  setPerPage: (perPage: number) => void;
  filter?: Filter;
  setFilter: (filter: Filter) => void;
}

function paramsToObject(search: URLSearchParams) {
  const result: Record<string, string> = {};
  for (const [key, value] of search.entries()) {
    result[key] = value;
  }
  return result;
}

function useListVariables<Filter>({
  defaultSort,
  defaultPagination,
  defaultFilter,
  prefix = "",
}: Args<Filter>): ListVariables<Filter> {
  const [search, setSearch] = useSearchParams();

  const rawFilter: string | null = search.get(`${prefix}filter`);

  const filter =
    typeof rawFilter === "string"
      ? (JSON.parse(decodeURIComponent(rawFilter)) as Filter)
      : defaultFilter;
  const setFilter = (newFilter: Filter) => {
    setSearch((prev) => ({
      ...paramsToObject(prev),
      [`${prefix}filter`]: encodeURIComponent(JSON.stringify(newFilter)),
    }));
  };

  const sort: SortInput = {
    field:
      search.get(`${prefix}sortField`) || defaultSort?.field || "updatedAt",
    order:
      (search.get(`${prefix}sortOrder`) || defaultSort?.order) === "ASC"
        ? "ASC"
        : "DESC",
  };

  const pagination = {
    page: parseInt(
      search.get(`${prefix}page`) || defaultPagination?.page?.toString() || "1"
    ),
    perPage: parseInt(
      search.get(`${prefix}perPage`) ||
        defaultPagination?.perPage?.toString() ||
        "30"
    ),
  };

  const setSort = (sortState: SortInput) => {
    setSearch((prev) => ({
      ...paramsToObject(prev),
      [`${prefix}sortField`]: sortState.field,
      [`${prefix}sortOrder`]: sortState.order,
    }));
  };

  const setPage = (page: number) => {
    setSearch((prev) => ({ ...paramsToObject(prev), [`${prefix}page`]: page.toString() }));
  };
  const setPerPage = (perPage: number) => {
    setSearch((prev) => ({
      ...paramsToObject(prev),
      [`${prefix}perPage`]: perPage.toString(),
    }));
  };

  return {
    sort,
    setSort,
    pagination,
    setPage,
    setPerPage,
    filter,
    setFilter,
  };
}

export default useListVariables;
