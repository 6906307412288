import React from "react";
import moment from "moment";
import { useForm } from "@mantine/form";
import {
  Button,
  JsonInput,
  Select,
  SimpleGrid,
  Text,
  TextInput,
} from "@mantine/core";
import { DateInput } from "@mantine/dates";
import {
  CreateAccountInput,
  useCreateAccountMutation,
  useUpdateAccountMutation,
} from "../graphql";
import { showSuccessNotification } from "../utils/showNotification";

type FormData = Omit<CreateAccountInput, "businessHours"> & {
  businessHours?: string | null;
};

interface Props {
  onSubmit: (id: string) => void;
  account?: CreateAccountInput & { id: string };
}

const STATUS = [
  { value: "PENDING_SETUP", label: "Pending setup" },
  { value: "TRIAL", label: "Trial" },
  { value: "ACTIVE", label: "Active" },
  { value: "CANCELED", label: "Canceled" },
  { value: "DELINQUENT", label: "Delinquent" },
  { value: "DEMO", label: "Demo" },
];

const AccountForm: React.FC<Props> = ({ onSubmit, account }) => {
  const [createAccount, createAccountResponse] = useCreateAccountMutation({
    refetchQueries: ["ListAccounts"],
    onCompleted: () => {
      showSuccessNotification("Account created successfully");
    }
  });
  const [updateAccount, updateAccountResponse] = useUpdateAccountMutation({
    refetchQueries: ["ListAccounts"],
    onCompleted: () => {
      showSuccessNotification("Account updated successfully");
    }
  });
  const form = useForm<FormData>({
    initialValues: {
      name: account?.name || "",
      phone: account?.phone || "",
      status: account?.status || "TRIAL",
      notificationsPhoneNumber: account?.notificationsPhoneNumber || undefined,
      jobFinishedSmsTemplate: account?.jobFinishedSmsTemplate || undefined,
      businessHours: account?.businessHours
        ? JSON.stringify(account?.businessHours)
        : "[]",
      stripeCustomerId: account?.stripeCustomerId || undefined,
      trialEndDate: account?.trialEndDate || undefined,
    },
  });

  const handleSubmit = async ({ businessHours, ...values }: FormData) => {
    if (account) {
      await updateAccount({
        variables: {
          input: {
            ...values,
            id: account.id,
            businessHours: JSON.parse(businessHours || "[]"),
          },
        },
      });
      onSubmit(account.id);
    } else {
      const result = await createAccount({
        variables: {
          input: {
            ...values,
            businessHours: JSON.parse(businessHours || "{}"),
          },
        },
      });
      if (result.data?.createAccount) {
        onSubmit(result.data.createAccount.id);
      }
    }
  };

  const errorMessage =
    createAccountResponse.error?.message ||
    updateAccountResponse.error?.message;

  return (
    <div>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <SimpleGrid>
          <TextInput label="Name" required {...form.getInputProps("name")} />
          <TextInput
            label="Phone"
            required
            type="tel"
            {...form.getInputProps("phone")}
          />
          <TextInput
            label="Notifications Phone Number"
            {...form.getInputProps("notificationsPhoneNumber")}
          />
          <TextInput
            label="Job Finished SMS Template"
            {...form.getInputProps("jobFinishedSmsTemplate")}
          />
          <Select
            label="Status"
            required
            data={STATUS}
            {...form.getInputProps("status")}
          />
          <JsonInput
            label="Business Hours"
            {...form.getInputProps("businessHours")}
          />
          <TextInput
            label="Stripe Customer ID"
            {...form.getInputProps("stripeCustomerId")}
          />

          <DateInput
            label="Trial Ends At"
            {...form.getInputProps("trialEndDate")}
            value={
              form.getInputProps("trialEndDate").value
                ? moment(form.getInputProps("trialEndDate").value).toDate() ||
                  new Date()
                : undefined
            }
            clearable
          />
        </SimpleGrid>
        {errorMessage && (
          <Text mt="md" c="red">
            {errorMessage}
          </Text>
        )}
        <Button fullWidth mt="md" type="submit">
          Save
        </Button>
      </form>
    </div>
  );
};

export default AccountForm;
