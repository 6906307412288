import React from "react";
import {
  Button,
  CloseButton,
  Group,
  Pagination,
  Paper,
  Skeleton,
  Table,
  TextInput,
  Title,
  rem,
} from "@mantine/core";
import { WorkShiftFilter, useListWorkShiftsQuery } from "../graphql";
import DateDisplay from "../components/DateDisplay";
import TableHeaderCell from "../components/TableHeaderCell";
import useListVariables from "../hooks/useListVariables";
import { IconPlus, IconSearch } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { usePermissions } from "../contexts/PermissionsContext";

const headers = [
  { label: "Clock In", field: "start" },
  { label: "Clock Out", field: "end" },
  { label: "Status", field: "status" },
  { label: "Employee", field: "employeeId" },
  { label: "Created At", field: "createdId" },
  { label: "Updated At", field: "updatedAt" },
];

const WorkShiftsPage: React.FC = () => {
  const navigate = useNavigate();
  const permissions = usePermissions();
  const listVariables = useListVariables<WorkShiftFilter>({
    defaultSort: { field: "createdAt", order: "DESC" },
  });
  const workshifts = useListWorkShiftsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      pagination: listVariables.pagination,
      sort: listVariables.sort,
      filter: listVariables.filter,
    },
  });

  return (
    <>
      <Group justify="space-between" mb="sm">
        <Title>Time Card Entries / Work Shifts</Title>
        <Group>
          <TextInput
            w={300}
            leftSection={<IconSearch />}
            placeholder="Search"
            value={listVariables.filter?.q || ""}
            onChange={(e) =>
              listVariables.setFilter({
                q: e.currentTarget.value || undefined,
              })
            }
            rightSection={
              listVariables.filter?.q && (
                <CloseButton
                  onClick={() => listVariables.setFilter({ q: undefined })}
                />
              )
            }
          />
          <Button
            rightSection={<IconPlus />}
            onClick={() => navigate("/app/workshifts/create")}
            display={permissions.can("createWorkShift") ? undefined : "none"}
          >
            Create
          </Button>
        </Group>
      </Group>
      <Paper w="100%">
        <Table.ScrollContainer minWidth={500}>
          <Table>
            <Table.Thead>
              <Table.Tr>
                {headers.map((header) => (
                  <TableHeaderCell
                    key={header.field}
                    label={header.label}
                    field={header.field}
                    vars={listVariables}
                  />
                ))}
                <Table.Th>Duration</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {workshifts.data?.listWorkShifts.data.map((workshift) => (
                <Table.Tr
                  key={workshift.id}
                  onClick={() => navigate(`/app/workshifts/${workshift.id}`)}
                >
                  <Table.Td>
                    <DateDisplay value={workshift.start} />
                  </Table.Td>
                  <Table.Td>
                    <DateDisplay value={workshift.end} />
                  </Table.Td>
                  <Table.Td>{workshift.status}</Table.Td>
                  <Table.Td>
                    {workshift.employee.firstName} {workshift.employee.lastName}
                  </Table.Td>
                  <Table.Td>
                    <DateDisplay value={workshift.createdAt} />
                  </Table.Td>
                  <Table.Td>
                    <DateDisplay value={workshift.updatedAt} />
                  </Table.Td>
                  <Table.Td>
                    {workshift.end &&
                      workshift.start &&
                      `${moment(workshift.end).diff(
                        workshift.start,
                        "hours"
                      )}h  ${moment(workshift.end).diff(
                        workshift.start,
                        "minutes"
                      ) % 60}m`}
                  </Table.Td>
                </Table.Tr>
              ))}
              {workshifts.loading &&
                Array.from({ length: listVariables.pagination.perPage }).map(
                  (_, index) => (
                    <Table.Tr key={index}>
                      {Array.from({ length: 6 }).map((_, index) => (
                        <Table.Td key={index}>
                          <Skeleton height={22} radius="none" />
                        </Table.Td>
                      ))}
                    </Table.Tr>
                  )
                )}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>
        <Group justify="space-evenly">
          <Pagination
            hidden={
              !workshifts.data?.listWorkShifts.total ||
              workshifts.data?.listWorkShifts.total <
                listVariables.pagination.perPage
            }
            mt={rem(20)}
            value={listVariables.pagination.page}
            onChange={listVariables.setPage}
            total={
              (workshifts.data?.listWorkShifts.total || 0) /
              listVariables.pagination.perPage
            }
          />
        </Group>
      </Paper>
    </>
  );
};

export default WorkShiftsPage;
