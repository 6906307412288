import React from "react";
import { useDrag } from "react-dnd";
import { ActionIcon, Box, Text, rem } from "@mantine/core";
import { CourtFragment } from "../graphql";
import TennisCourtSVG from "./TennisCourtSVG";
import { IconEdit } from "@tabler/icons-react";

interface Props {
  court: CourtFragment;
  draggable: boolean;
  onEditClick?: (court: CourtFragment) => void;
  scale?: number;
}

const DraggableCourt: React.FC<Props> = ({
  court,
  draggable,
  onEditClick,
  ...props
}) => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "court",
      item: court,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [court],
  );
  const scale = props.scale || 2;
  const lineColor = "white";

  return (
    <Box
      pos="absolute"
      ref={drag}
      top={court.location.y}
      left={court.location.x}
      h={`calc(60px * ${scale})`}
      w={`calc(120px * ${scale})`}
      bg={court.color || "green"}
      data-testid="court"
      style={{
        opacity: isDragging ? 0 : 1,
        cursor: draggable ? "move" : "default",
        transform: `rotate(${court.location.rotation}deg)`,
      }}
    >
      <Text pos="absolute" left={rem(4)} c={lineColor}>
        {court.name}
      </Text>
      {onEditClick && (
        <ActionIcon
          onClick={() => onEditClick(court)}
          pos="absolute"
          variant="transparent"
          color={lineColor}
          size="xs"
          bottom={2}
          right={2}
          style={{
            transform: `rotate(${court.location.rotation}deg)`,
          }}
        >
          <IconEdit size={15} />
        </ActionIcon>
      )}
      <TennisCourtSVG scale={scale} lineColor={lineColor} />
    </Box>
  );
};

export default DraggableCourt;
