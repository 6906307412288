import { modals } from "@mantine/modals";
import { CourtFragment } from "../graphql";
import CourtForm from "../components/CourtForm";

interface Args {
  onComplete: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
}

function useCourtModal(args: Args) {
  const openCourtModal = (court?: CourtFragment) => {
    modals.open({
      title: court ? "Edit Court" : "Add Court",
      children: (
        <CourtForm
          cols={1}
          court={court}
          onSubmit={() => {
            args.onComplete();
            modals.closeAll();
          }}
        />
      ),
      onClose: args.onCancel,
    });
  };

  return { openCourtModal };
}

export default useCourtModal;
