import { modals } from "@mantine/modals";
import {
  ClockInOutInput,
  useClockInMutation,
  useClockOutMutation,
  useGetActiveWorkShiftQuery,
} from "../graphql";
import ClockInOutForm from "../components/ClockInOutForm";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../utils/showNotification";

interface Args {
  onComplete: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
}

function useClockInOutModal(args: Args) {
  const activeWorkShiftResp = useGetActiveWorkShiftQuery();
  const [clockIn, clockInResp] = useClockInMutation({
    refetchQueries: ["GetActiveWorkShift"],
    onCompleted: () => {
      showSuccessNotification("Clocked in successfully");
    },
    onError: (error) => {
      showErrorNotification(error.message);
    },
  });
  const [clockOut, clockOutResp] = useClockOutMutation({
    refetchQueries: ["GetActiveWorkShift"],
    onCompleted: () => {
      showSuccessNotification("Clocked out successfully");
    },
    onError: (error) => {
      showErrorNotification(error.message);
    },
  });

  const activeWorkShift = activeWorkShiftResp.data?.getActiveWorkShift;

  const handleSubmit = async (data: ClockInOutInput) => {
    if (activeWorkShift) {
      await clockOut({ variables: { input: data } });
    } else {
      await clockIn({ variables: { input: data } });
    }
    modals.closeAll();
    args.onComplete();
  };

  const openClockInOutModal = () => {
    modals.open({
      title: activeWorkShift ? "Clock Out" : "Clock In",
      children: (
        <ClockInOutForm
          onSubmit={handleSubmit}
          loading={clockInResp.loading || clockOutResp.loading}
          clockIn={activeWorkShift ? false : true}
        />
      ),
      onClose: args.onCancel,
    });
  };

  return { openClockInOutModal };
}

export default useClockInOutModal;
