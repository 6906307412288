import { Button } from "@mantine/core";
import React from "react";
import { IconCheck } from "@tabler/icons-react";
import { useUpdateJobsStatusMutation } from "../graphql";
import { showSuccessNotification } from "../utils/showNotification";
import { usePermissions } from "../contexts/PermissionsContext";

interface Props {
  jobIds?: string[];
  onComplete?: () => void;
  hidden?: boolean;
}

const MarkJobsAsTodoButton: React.FC<Props> = ({
  jobIds,
  onComplete,
  hidden,
}) => {
  const permissions = usePermissions();
  const [updateJobsStatus, updateJobsStatusResponse] =
    useUpdateJobsStatusMutation({
      onCompleted: () => {
        showSuccessNotification("Jobs marked as todo");
        onComplete?.();
      },
    });

  const loading = updateJobsStatusResponse.loading;

  return (
    <Button
      style={{ display: hidden ? "none" : "" }}
      onClick={() => {
        updateJobsStatus({
          variables: {
            input: {
              status: "TODO",
              ids: jobIds || [],
              sendNotification: false,
            },
          },
        });
      }}
      disabled={!jobIds || jobIds.length === 0}
      loading={loading}
      rightSection={<IconCheck />}
      color="blue"
      display={!permissions.can("updateJob") ? "hidden" : undefined}
    >
      Mark Job{jobIds && jobIds.length > 1 ? "s" : ""} as Todo
    </Button>
  );
};

export default MarkJobsAsTodoButton;
