import React from "react";
import moment from "moment";
import { Button, SimpleGrid, Text, Title } from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";

const PayrollReportForm: React.FC = () => {
  const [start, setStart] = React.useState<Date | null>(
    moment().startOf("month").toDate()
  );
  const [end, setEnd] = React.useState<Date | null>(
    moment().endOf("month").toDate()
  );

  const generateReport = () => {
    if (start === null || end === null) return;

    window.open(
      `${
        window.location.origin
      }/app-reports/payroll?start=${start.toISOString()}&end=${end.toISOString()}`,
      "payroll-report",
      "directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=1000,height=600"
    );
  };

  return (
    <>
      <Title mb="sm" order={2}>
        Payroll Report
      </Title>
      <Text size="sm" mb="sm">
        With the Payroll Report, you'll be able to select a specific pay period
        by setting the start and end dates. Once these dates are determined, the
        system will compile all relevant information regarding the employee's
        work shifts, as well as the number of rackets they have strung during
        this timeframe. This comprehensive report aids in ensuring accurate and
        efficient payroll management, tailored specifically to the unique
        operations of your tennis shop.
      </Text>
      <SimpleGrid cols={2} mb="sm">
        <DateTimePicker
          label="Pay Period Start"
          valueFormat="MMMM D, YYYY hh:mm A"
          value={start}
          onChange={setStart}
          maxDate={end !== null ? end : undefined}
        />
        <DateTimePicker
          label="Pay Period End"
          valueFormat="MMMM D, YYYY hh:mm A"
          value={end}
          onChange={setEnd}
          minDate={start !== null ? start : undefined}
        />
      </SimpleGrid>
      <Button onClick={generateReport}>Generate Report</Button>
    </>
  );
};

export default PayrollReportForm;
