import { Center, Group, Table, UnstyledButton, rem, Text } from "@mantine/core";
import {
  IconChevronUp,
  IconChevronDown,
  IconSelector,
} from "@tabler/icons-react";
import { ListVariables } from "../hooks/useListVariables";

type FilterType = "String" | "Number" | "Date" | "Boolean";

interface Props<Filter> {
  label: string;
  field?: string;
  vars: ListVariables<Filter>;
  type?: FilterType;
}

function TableHeaderCell<Filter>({ label, field, vars }: Props<Filter>) {
  const sorted = vars.sort.field === field;
  const reversed = vars.sort.order === "DESC";
  const Icon = sorted
    ? reversed
      ? IconChevronUp
      : IconChevronDown
    : IconSelector;

  return (
    <Table.Th>
      <Group justify="space-between">
        <Text fw={500} fz="sm">
          {label}
        </Text>
        {field && (
          <UnstyledButton
            onClick={() =>
              vars.setSort({
                field,
                order: sorted && reversed ? "ASC" : "DESC",
              })
            }
          >
            <Center>
              <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
            </Center>
          </UnstyledButton>
        )}
      </Group>
    </Table.Th>
  );
}

export default TableHeaderCell;
