import React from "react";
import {
  Paper,
  Title,
  Text,
  SimpleGrid,
  Group,
  Button,
  Anchor,
} from "@mantine/core";
import { useGetJobQuery } from "../graphql";
import { useNavigate, useParams } from "react-router-dom";
import DateDisplay from "../components/DateDisplay";
import { IconEdit } from "@tabler/icons-react";
import FinishJobsButton from "../components/FinishJobsButton";
import MarkJobsAsTodoButton from "../components/MarkJobsAsTodoButton";
import DeleteButton from "../components/DeleteButton";
import { usePermissions } from "../contexts/PermissionsContext";

const JobsDetailsPage: React.FC = () => {
  const navigate = useNavigate();
  const permissions = usePermissions();
  const params = useParams<{ id: string }>();
  const jobId = `${params.id}`;

  const getJob = useGetJobQuery({
    variables: {
      id: jobId,
    },
  });

  const job = getJob.data?.getJob;
  return (
    <>
      <Group justify="space-between" mb="sm">
        <Title>Job Details</Title>
        <Group gap="sm">
          <MarkJobsAsTodoButton
            hidden={job?.status === "TODO"}
            jobIds={[jobId]}
            onComplete={() => getJob.refetch()}
          />
          <FinishJobsButton
            jobIds={[jobId]}
            hidden={job?.status === "FINISHED"}
            onComplete={() => getJob.refetch()}
          />
          <Button
            rightSection={<IconEdit />}
            onClick={() => navigate(`/app/jobs/${params.id}/update`)}
            display={!permissions.can("updateJob") ? "hidden" : undefined}
          >
            Update
          </Button>
          <DeleteButton
            id={jobId}
            onCompleted={() => navigate("/app/jobs")}
            type="job"
          />
        </Group>
      </Group>
      <Paper>
        <SimpleGrid cols={{ sm: 1, md: 2, lg: 3 }}>
          <Text>
            <strong>Customer Name:</strong>{" "}
            <Anchor
              onClick={(e) => {
                e.preventDefault();
                navigate(`/app/customers/${job?.customerId}`);
              }}
              href={`/app/customers/${job?.customerId}`}
            >
              {job?.customer?.firstName} {job?.customer?.lastName}
            </Anchor>
          </Text>
          <Text>
            <strong>Job Name:</strong> {job?.name}
          </Text>
          <Text>
            <strong>Racket:</strong> {job?.racket}
          </Text>
          <Text>
            <strong>String:</strong> {job?.stringName}
          </Text>
          <Text>
            <strong>Gauge:</strong> {job?.gauge}
          </Text>
          <Text>
            <strong>Tension:</strong> {job?.tension}
          </Text>
          <Text>
            <strong>Status:</strong> {job?.status}
          </Text>
          <Text>
            <strong>Recieved At:</strong>{" "}
            <DateDisplay value={job?.receivedAt} />
          </Text>
          <Text>
            <strong>Finished At:</strong>{" "}
            <DateDisplay value={job?.finishedAt} />
          </Text>
          <Text>
            <strong>Finished By:</strong>{" "}
            <Anchor
              onClick={(e) => {
                e.preventDefault();
                navigate(`/app/employees/${job?.finishedById}`);
              }}
              href={`/app/employees/${job?.finishedById}`}
            >
              {job?.finishedBy?.firstName} {job?.finishedBy?.lastName}
            </Anchor>
          </Text>
          <Text>
            <strong>Updated At:</strong> <DateDisplay value={job?.updatedAt} />
          </Text>
          <Text>
            <strong>Created At:</strong> <DateDisplay value={job?.createdAt} />
          </Text>
        </SimpleGrid>
      </Paper>
    </>
  );
};

export default JobsDetailsPage;
