import React from "react";
import { Group, Paper, Title } from "@mantine/core";

import AccountSettingsForm from "../components/AccountSettingsForm";
import {
  useGetMyAccountQuery,
  useGetMyAccountBillingInfoQuery,
} from "../graphql";
import ProductCatalog from "../components/ProductCatalog";
import ManageBillingPanel from "../components/ManageBillingPanel";

const SettingsPage: React.FC = () => {
  const getMyAccount = useGetMyAccountQuery();
  const billingInfo = useGetMyAccountBillingInfoQuery();
  const account = getMyAccount.data?.myAccount;

  const currentSubscription =
    billingInfo.data?.myAccount?.billingState?.subscriptionId;

  return (
    <>
      <Title mb="sm">Settings</Title>
      <Paper>
        <Title order={3}>Account Settings</Title>
        {account && <AccountSettingsForm account={account} />}
      </Paper>
      <Paper mt="lg">
        <Group justify="space-between">
          <Title order={3}>Billing Settings</Title>
        </Group>
        {currentSubscription ? (
          <ManageBillingPanel />
        ) : (
          <>
            <Title ta="center" order={4}>
              Select a Plan
            </Title>
            <ProductCatalog />
          </>
        )}
      </Paper>
    </>
  );
};

export default SettingsPage;
