const envConfigs = {
  prod: {
    API_HOST: "https://api.tennisshopguru.com",
    REALTIME_API_HOST: "ws.tennisshopguru.com",
    AUTH_DOMAIN: "signin.tennisshopguru.com",
    AUTH_CLIENT_ID: "65lh45f3isj7lqkg2482ldqhq2",
    STRIPE_PUBLIC_KEY:
      "pk_test_51OhfdNHGDkiJfMLC9b8JIvkLDYAT1JgfqiK4NoAl0TrN4zzdkZ0ODU0wYG4QcxCEqd69ZVsoOjK6oi1L5kTRqFQH00j6DSt2qx",
  },
  local: {
    API_HOST: "http://localhost:8000",
    REALTIME_API_HOST: "ws.tennisshopguru.com",
    AUTH_DOMAIN: "signin.tennisshopguru.com",
    AUTH_CLIENT_ID: "65lh45f3isj7lqkg2482ldqhq2",
    STRIPE_PUBLIC_KEY:
      "pk_test_51OhfdNHGDkiJfMLC9b8JIvkLDYAT1JgfqiK4NoAl0TrN4zzdkZ0ODU0wYG4QcxCEqd69ZVsoOjK6oi1L5kTRqFQH00j6DSt2qx",
  },
};

type Env = keyof typeof envConfigs;

const ENV = (import.meta.env.VITE_ENV || "local") as Env;

const config = {
  ENV: ENV,
  SHA: import.meta.env.VITE_SHA || "local",
  API_HOST: import.meta.env.VITE_API_HOST || envConfigs[ENV].API_HOST,
  REALTIME_API_HOST:
    import.meta.env.VITE_REALTIME_API_HOST || envConfigs[ENV].REALTIME_API_HOST,
  AUTH_DOMAIN: import.meta.env.VITE_AUTH_DOMAIN || envConfigs[ENV].AUTH_DOMAIN,
  AUTH_CLIENT_ID:
    import.meta.env.VITE_AUTH_CLIENT_ID || envConfigs[ENV].AUTH_CLIENT_ID,
  STRIPE_PUBLIC_KEY:
    import.meta.env.VITE_STRIPE_PUBLIC_KEY || envConfigs[ENV].STRIPE_PUBLIC_KEY,
};

export default config;
