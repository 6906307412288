import React from "react";
import { Button, Group, SimpleGrid, Stack, Text, Title } from "@mantine/core";

import { useGetMyAccountBillingInfoQuery } from "../graphql";
import PaymentMethodDisplay from "../components/PaymentMethodDisplay";
import useBillingPortalLink from "../hooks/useBillingPortalLink";

const ManageBillingPanel: React.FC = () => {
  const billingInfo = useGetMyAccountBillingInfoQuery();
  const billingPortal = useBillingPortalLink();

  const paymentMethods = billingInfo.data?.myAccount?.paymentMethods || [];
  const currentSubscriptionId =
    billingInfo.data?.myAccount?.billingState?.subscriptionId;

  return (
    <>
      <SimpleGrid spacing="lg">
        <div>
          <Title order={4}>Current Subscription</Title>
          <Text>{currentSubscriptionId}</Text>
          <Button
            loading={billingPortal.loading}
            component="a"
            href={billingPortal.billingPortalLink?.url}
          >
            Manage Subscription
          </Button>
        </div>
        <div>
          <Group justify="space-between">
            <Title order={4}>Payment Methods</Title>
          </Group>

          <Stack>
            {paymentMethods.map((paymentMethod) => (
              <PaymentMethodDisplay key={paymentMethod.id} {...paymentMethod} />
            ))}
          </Stack>
        </div>
      </SimpleGrid>
    </>
  );
};

export default ManageBillingPanel;
