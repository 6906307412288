import { Select, SelectProps } from "@mantine/core";
import React from "react";
import { useListEmployeesQuery } from "../graphql";

const EmployeeInput: React.FC<SelectProps> = (props) => {
  const { data } = useListEmployeesQuery();

  const employeeOptions = data?.listEmployees.data.map((employee) => ({
    value: employee.id,
    label: `${employee.firstName} ${employee.lastName}`,
  }));

  return <Select {...props} data={employeeOptions} clearable />;
};

export default EmployeeInput;
