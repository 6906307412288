import React from "react";
import useBillingProductCatalogSession from "../hooks/useBillingProductCatalogSession";
import config from "../utils/config";

const ProductCatalog: React.FC = () => {
  const productCatalogData = useBillingProductCatalogSession();

  if (productCatalogData.loading) {
    return <div>Loading...</div>;
  }

  if (productCatalogData.error) {
    return <div>{productCatalogData.error}</div>;
  }

  if (productCatalogData.customerSession?.client_secret) {
    return (
      <>
        {/* @ts-expect-error Stripe BS */}
        <stripe-pricing-table
          pricing-table-id="prctbl_1OifxQHGDkiJfMLCIlLBFKq2"
          publishable-key={config.STRIPE_PUBLIC_KEY}
          customer-session-client-secret={
            productCatalogData.customerSession.client_secret
          }
        >
          {/* @ts-expect-error Stripe BS */}
        </stripe-pricing-table>
      </>
    );
  }

  return <></>;
};

export default ProductCatalog;
