import React from "react";
import { Paper, Title } from "@mantine/core";
import PayrollReportForm from "../components/PayrollReportForm";

const ReportsPage: React.FC = () => {
  return (
    <>
      <Title mb="sm">Reports</Title>
      <Paper w="100%">
        <PayrollReportForm />
      </Paper>
    </>
  );
};

export default ReportsPage;
