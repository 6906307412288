import React from "react";
import EmployeeInput from "./EmployeeInput";
import { useForm } from "@mantine/form";
import { useAuth } from "../contexts/AuthContext";
import { Button, SimpleGrid } from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import moment from "moment";
import { ClockInOutInput } from "../graphql";

interface Props {
  onSubmit: (data: ClockInOutInput) => void;
  loading?: boolean;
  clockIn?: boolean;
}

const ClockInOutForm: React.FC<Props> = ({ onSubmit, loading, clockIn }) => {
  const { user } = useAuth();
  const form = useForm<ClockInOutInput>({
    initialValues: {
      employeeId: user?.employee_id || "",
      clockTime: new Date().toISOString(),
    },
  });

  const handleSubmit = (formData: ClockInOutInput) => {
    onSubmit(formData);
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <SimpleGrid cols={1}>
        <EmployeeInput
          label="Employee"
          {...form.getInputProps("employeeId")}
          disabled={loading}
        />
        <DateTimePicker
          label="Clock Time"
          valueFormat="MMM DD, YYYY hh:mm A"
          {...form.getInputProps("clockTime")}
          minDate={moment().subtract(8, "hours").toDate()}
          maxDate={moment().add(1, "hours").toDate()}
          value={
            form.getInputProps("clockTime").value
              ? moment(form.getInputProps("clockTime").value).toDate() ||
                new Date()
              : undefined
          }
          clearable
        />
        <Button type="submit" loading={loading}>
          Clock {clockIn ? "In" : "Out"}
        </Button>
      </SimpleGrid>
    </form>
  );
};

export default ClockInOutForm;
