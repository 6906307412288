import React from "react";
import config from "../utils/config";
import { auth } from "../utils/auth";

interface BillingPortalLink {
  url: string;
}

interface HookReturn {
  billingPortalLink?: BillingPortalLink;
  loading: boolean;
  error?: string;
}

function useBillingPortalLink(): HookReturn {
  const [loading, setLoading] = React.useState(true);
  const [billingPortalLink, setBillingPortalLink] =
    React.useState<BillingPortalLink>();
  const [error, setError] = React.useState<string>();

  React.useEffect(() => {
    setLoading(true);
    auth.getAccessToken().then((accessToken: string | undefined) => {
      fetch(`${config.API_HOST}/billing/portal`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
        .then((res) => res.json())
        .then((data) => {
          setBillingPortalLink(data);
          setLoading(false);
        })
        .catch((error) => {
          setError(error.message);
          setLoading(false);
        });
    });
  }, []);

  return {
    loading,
    billingPortalLink,
    error,
  };
}

export default useBillingPortalLink;
