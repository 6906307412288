import React from "react";
import config from "../utils/config";
import { auth } from "../utils/auth";

interface CustomerSession {
  client_secret: string;
  created: number;
  expires_at: number;
  livemode: boolean;
}

interface HookReturn {
  customerSession?: CustomerSession;
  loading: boolean;
  error?: string;
}

function useBillingProductCatalogSession(): HookReturn {
  const [loading, setLoading] = React.useState(true);
  const [customerSession, setCustomerSession] =
    React.useState<CustomerSession>();
  const [error, setError] = React.useState<string>();

  const getSession = React.useCallback(async () => {
    setLoading(true);
    try {
      const accessToken = await auth.getAccessToken();
      const response = await fetch(
        `${config.API_HOST}/billing/product-catalog`,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        },
      );

      const data = await response.json();
      if (response.status === 401)
        throw new Error("Unauthorized to manage billing");
      setCustomerSession(data);
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      } else {
        setError("An unknown error occurred");
      }
    } finally {
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    getSession();
  }, [getSession]);

  return {
    loading,
    customerSession,
    error,
  };
}

export default useBillingProductCatalogSession;
