import React from "react";
import { Paper, Title } from "@mantine/core";
import WorkShiftForm from "../components/WorkShiftForm";
import { useNavigate, useParams } from "react-router-dom";
import { useGetWorkShiftQuery } from "../graphql";

const WorkShiftsUpdatePage: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const getWorkShift = useGetWorkShiftQuery({
    variables: { id: `${params.id}` },
  });

  const workshift = getWorkShift.data?.getWorkShift;

  return (
    <>
      <Title mb="sm">Update WorkShift</Title>
      <Paper>
        {workshift && (
          <WorkShiftForm
            workshift={workshift}
            onSubmit={(id) => navigate(`/app/workshifts/${id}`)}
          />
        )}
      </Paper>
    </>
  );
};

export default WorkShiftsUpdatePage;
