import React from "react";
import { useSearchParams } from "react-router-dom";
import { Title, Text, Button } from "@mantine/core";
import { useConfirmSignUpMutation } from "../graphql";
import { useAuth } from "../contexts/AuthContext";

const SignUpConfirmPage: React.FC = () => {
  const [search] = useSearchParams();
  const auth = useAuth();
  const sub = search.get("sub") || "";

  const [confirmSignUp, { loading }] = useConfirmSignUpMutation();

  React.useEffect(() => {
    confirmSignUp({
      variables: {
        input: { sub },
      },
    });
  }, [sub, confirmSignUp]);

  return (
    <>
      <Title ta="center">Welcome!</Title>
      <Text ta="center">
        Thank you for confirming your email! You're ready to go!
      </Text>
      <Button
        loading={loading}
        fullWidth
        mt="lg"
        size="xl"
        color="green"
        onClick={() => auth.login("/app")}
      >
        Login!
      </Button>
    </>
  );
};

export default SignUpConfirmPage;
