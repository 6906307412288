import { Select, SelectProps } from "@mantine/core";
import React from "react";
import { useGetCustomerLazyQuery, useListCustomersQuery } from "../graphql";

const CustomerInput: React.FC<SelectProps> = (props) => {
  const { data, refetch } = useListCustomersQuery();
  const [getCustomer, getCustomerResponse] = useGetCustomerLazyQuery();
  const [search, setSearch] = React.useState("");

  const value = props.value;

  React.useEffect(() => {
    if (value) {
      getCustomer({ variables: { id: value } });
    }
  }, [value, getCustomer]);

  React.useEffect(() => {
    if (search) {
      refetch({
        filter: {
          q: search,
        },
      });
    }
  }, [search, refetch]);

  const customerOptions =
    data?.listCustomers.data.map((customer) => ({
      value: customer.id,
      label: `${customer.firstName} ${customer.lastName}`,
    })) || [];

  const selectedCustomer =
    value &&
    getCustomerResponse.data?.getCustomer &&
    !customerOptions.find((option) => option.value === value)
      ? [
          {
            value: getCustomerResponse.data.getCustomer.id,
            label: `${getCustomerResponse.data.getCustomer.firstName} ${getCustomerResponse.data.getCustomer.lastName}`,
          },
        ]
      : [];

  return (
    <Select
      {...props}
      searchable
      searchValue={search}
      onSearchChange={setSearch}
      filter={({ options }) => options}
      data={[...customerOptions, ...selectedCustomer]}
      clearable
    />
  );
};

export default CustomerInput;
