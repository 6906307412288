import React from "react";
import { Button, ButtonProps } from "@mantine/core";
import {
  useDeleteAccountMutation,
  useDeleteCourtMutation,
  useDeleteCustomerMutation,
  useDeleteEmployeeMutation,
  useDeleteJobMutation,
  useDeleteWorkShiftMutation,
} from "../graphql";
import { IconTrash } from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import { showSuccessNotification } from "../utils/showNotification";
import { usePermissions } from "../contexts/PermissionsContext";

interface Props {
  id?: string;
  onCompleted: () => void;
  type: "account" | "job" | "customer" | "employee" | "workshift" | "court";
}

const DeleteButton: React.FC<Props & ButtonProps> = ({ id, onCompleted, type, ...buttonProps }) => {
  const permissions = usePermissions();
  const handleCompleted = () => {
    showSuccessNotification(`Deleted ${type} successfully`);
    onCompleted();
  };

  const [deleteAccount, accountResp] = useDeleteAccountMutation({
    onCompleted: handleCompleted,
  });
  const [deleteJob, jobResp] = useDeleteJobMutation({
    onCompleted: handleCompleted,
  });
  const [deleteCustomer, customerResp] = useDeleteCustomerMutation({
    onCompleted: handleCompleted,
  });
  const [deleteEmployee, employeeResp] = useDeleteEmployeeMutation({
    onCompleted: handleCompleted,
  });
  const [deleteWorkShift, workshiftResp] = useDeleteWorkShiftMutation({
    onCompleted: handleCompleted,
  });
  const [deleteCourt, courtResp] = useDeleteCourtMutation({
    onCompleted: handleCompleted,
  });

  const handleDelete = () => {
    if (!id) return;
    if (type === "account") {
      deleteAccount({ variables: { id } });
    } else if (type === "job") {
      deleteJob({ variables: { id } });
    } else if (type === "customer") {
      deleteCustomer({ variables: { id } });
    } else if (type === "employee") {
      deleteEmployee({ variables: { id } });
    } else if (type === "workshift") {
      deleteWorkShift({ variables: { id } });
    } else if (type === "court") {
      deleteCourt({ variables: { id } });
    }
  };

  const openModal = () => {
    modals.openConfirmModal({
      title: "Are you sure?",
      children: "This action cannot be undone",
      onConfirm: handleDelete,
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
    });
  };

  const loading =
    accountResp.loading ||
    jobResp.loading ||
    customerResp.loading ||
    employeeResp.loading ||
    workshiftResp.loading ||
    courtResp.loading;

  if (type === "court" && !permissions.can("deleteCourt")) {
    return null;
  } else if (type === "account" && !permissions.can("deleteAccount")) {
    return null;
  } else if (type === "job" && !permissions.can("deleteJob")) {
    return null;
  } else if (type === "customer" && !permissions.can("deleteCustomer")) {
    return null;
  } else if (type === "employee" && !permissions.can("deleteEmployee")) {
    return null;
  } else if (type === "workshift" && !permissions.can("deleteWorkShift")) {
    return null;
  }


  return (
    <Button
      disabled={!id}
      color="red"
      onClick={openModal}
      loading={loading}
      rightSection={<IconTrash />}
      {...buttonProps}
    >
      Delete
    </Button>
  );
};

export default DeleteButton;
